import { useContext, useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import useScrollListener from "./UseScrollListener";

import "./landingNavbar.scss";
import "../../components/navbar/navbar.scss";

import { Logo } from "../../components/navbar/logo/logo";
import { LanguageSelector } from "../../components/navbar/languageSelector/LanguageSelector";
import { DesktopElement } from "../../components/navbar/desktopElement/desktopElement";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { logout } from "../../utilities/rest/logout";
import { NotificationIcon } from "../Icons/NotificationIcon";
import { CloseIcon } from "../Icons/CloseIcon";
import { CloseIcon300 } from "../Icons/CloseIcon300";
import { ArrowForwardIos400 } from "../Icons/ArrowForwardIos400";
import MediaQuery from "react-responsive";



export const LandingNavbar = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const authContext = useContext(AuthContext);
    
    const [hidden, setHidden] = useState(false);
    const [scrollable, setScrollable] = useState(true);

    const [selectedLanguage, setSelectedLanguage] = useState("PL");
    const [langActivePanel, setLangActivePanel] = useState(false);
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

    const [notificationDisabled, setNotificationDisabled] = useState(false);

    const [desktopMenuElements, setDesktopMenuElements] = useState([
        {   first: true,
            icon: "person",
            text: t("navbar.createAccount"),
            path: "signup"
        },
        {   
            icon: "login",
            text: t("navbar.login"),
            path: "login"
        },
        {
            icon: "shopping_bag",
            text: t("navbar.coursesShop"),
            path: "shop"
        },
        {
            last: true,
            icon: "language",
            text: `${t("navbar.changeLanguage")} (${selectedLanguage})`,
            path: "language"
        }
    ]);

    const scroll = useScrollListener();
  

    const disableScroll = (subsite: string) => {
        const excludedSites = ["coursePreview"];

        const scrollable = !(excludedSites.includes(subsite));  

        setScrollable(scrollable);
    }

    const disableNotification = (subsite: string) => {
        const excludedSites = ["coursePreview", "shop", "paymentConfirmation"];
    
        const disabled = excludedSites.includes(subsite);  

        if(!notificationDisabled) setNotificationDisabled(disabled);
    }

    useEffect(() => {
        const parts = location.pathname.split('/');
        const subsite = parts[1];

        disableScroll(subsite);
        disableNotification(subsite);
  
    });

    useEffect(() => {
  
        if (scroll.y > 150 && scroll.y - scroll.lastY > 0  && !mobileMenuOpened && scrollable)
            setHidden(true);
        else setHidden(false);
  
    }, [scroll.y, scroll.lastY]);

    const handleMenuOpeningButton = () => {
        setMobileMenuOpened(!mobileMenuOpened);
    }

    const hideButtons = () => {
        const parts = location.pathname.split('/');
        const subsite = parts[1];
  
        const excludedSites = ["coursePreview", "paymentConfirmation"];

        return (excludedSites.includes(subsite));
    }

    useEffect(() => {
        let firstTwo = [];

        if(!authContext.loggedIn) {
            firstTwo = [
                {   first: true,
                    icon: "person",
                    text: t("navbar.createAccount"),
                    path: "signup"
                },
                { 
                    icon: "login",
                    text: t("navbar.login"),
                    path: "login"
                }
            ];
        } else {
            firstTwo = [
                {
                    first: true,
                    icon: "library_books",
                    text: t("navbar.myCourses"),
                    path: "userPanel"
                },
                {
                    icon: "logout",
                    text: t("navbar.logout"),
                    path: "logout"
                }
            ];
        }

        setDesktopMenuElements([...firstTwo, ...desktopMenuElements.slice(2)]);
  
    }, [authContext.loggedIn]);

    const handleNavbarClass = () => {
        let base = "nav";
        if(hidden) base += " nav--hidden";

        base += scrollable ? " nav--scrollable" : " nav--not-scrollable";

        base += notificationDisabled ? " nav--notifications-disabled" : " nav--notifications-enabled"; 

        return base
    }

    return (
        <header className={handleNavbarClass()}>

            
            <div className="nav__main">
                <div className="nav__logo-wrapper" onClick={() => navigate('/')}>
                    <Logo/>
                </div>

                <div className="nav__buttons">
                    {

                        hideButtons() ? "" : (
                            (!authContext.loggedIn) ? (
                                <>
                                    <div
                                        className="nav__buttons__button nav__buttons__button--left"
                                        onClick={() => navigate('/login')}
                                    >
                                        <span>Logowanie</span>
                                    </div>

                                    {/* <div 
                                        className="nav__buttons__button nav__buttons__button--right yellow-button"
                                        onClick={() => navigate('/signup')}
                                    >
                                        <span>{t("landingNavbar.createAccount")}</span>
                                    </div> */}
                                </>
                            ) : (
                                <>

                                    <div
                                        className="nav__buttons__button nav__buttons__button--left"
                                        onClick={() => { 
                                            logout(); 
                                            authContext.setLoggedIn(false); 
                                        }}
                                    >
                                        <span>{t("landingNavbar.logout")}</span>
                                    </div>

                                    <div 
                                        className="nav__buttons__button nav__buttons__button--right yellow-button"
                                        onClick={() => navigate('/userPanel')}
                                    >
                                        <span>{t("landingNavbar.goToPlatform")}</span>
                                    </div>
                                </>
                            )
                        )  
                    }
                
                    <div className="nav__burger-landing">
                        <div 
                            id="navigation__user-panel__my-profile__hamburger-menu" 
                            onClick={ handleMenuOpeningButton } 
                            className={ mobileMenuOpened ? "active" : ""}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className={`rolledUserMenu ${mobileMenuOpened? "rolledUserMenu--active": ""}`}>
                        { 
                            desktopMenuElements.map(
                                (el, index) => { 
                                    const element = <DesktopElement 
                                        key={index} 
                                        icon={el.icon} 
                                        text={el.text} 
                                        first={el.first} 
                                        last={el.last} 
                                        path={el.path} 
                                        handleMenuOpeningMobile={handleMenuOpeningButton}/>;
                                        
                                        if(el.path !== "language") 
                                            return element
                                        else return React.cloneElement(
                                            element, 
                                            { setLangActivePanel }
                                        );
                                }
                            )
                        }
                    </div> 

                    <LanguageSelector 
                        selectedLanguage={selectedLanguage} 
                        setSelectedLanguage={setSelectedLanguage} 
                        activePanel={langActivePanel} 
                        setActivePanel={setLangActivePanel}
                    />
                </div>
            </div>

            <div 
                onClick={() => navigate('/coursePreview/674258c29ee0381352690994')}
                className={`nav__sub-header nav__sub-header--blue-baner nav__sub-header--${notificationDisabled ? 'inactive' : 'active'}`}>
                
                

                <span className="nav_sub-header__text">
                    <MediaQuery minWidth={951}>
                        kurs Całki nieoznaczone - już dostępny! 🎉
                    </MediaQuery>
                    <MediaQuery maxWidth={950}>
                        kurs Całki już dostępny 🎉
                    </MediaQuery>
                    
                </span>
                
            </div>

            <div 
                onClick={() => navigate('/shop')}
                className={`nav__sub-header nav__sub-header--green-baner nav__sub-header--${notificationDisabled ? 'inactive' : 'active'}`}>
                
                

                <span className="nav_sub-header__text">
                    <MediaQuery minWidth={951}>
                        Promocja -30% na wszystkie kursy 🚀  
                    </MediaQuery>
                    <MediaQuery maxWidth={950}>
                        Promocja -30% 🚀
                    </MediaQuery>
                    
                </span>
                
            </div>


            {/* <div className="nav__promo" 
                onClick={() => {
                    window.scrollTo(0, 0);
                    navigate('/shop')}}
            >
                <div className="nav__promo__left-side">
                    <div className="nav__promo__left-side__scroll-text">
                        <b className="yellow-bold">BLACK WEEK 2024</b> - Każdy kurs dostępny <b className="yellow-bold">50%</b> taniej 💸 🚀
                        <b className="white-bold">-</b>
                        <b className="yellow-bold">BLACK WEEK 2024</b> - Każdy kurs dostępny 50% taniej 💸 🚀
                    </div>
                </div>

                <div className="nav__promo__right-side">
                    <button onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/shop')}
                    }>
                        
                        <span>Skorzystaj z promocji</span> 
                        <ArrowForwardIos400/>
                    </button>
                </div>

            </div> */}
        </header>
    );
};

